/* @import url("https://fonts.googleapis.com/css2?family=Pacifico&family=Poppins:ital,wght@0,300;0,500;0,600;0,700;0,800;0,900;1,300&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Passions+Conflict&display=swap"); */

@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300&display=swap');


.carousel-indicators li {
  display: none !important;
}
.carousel-control-next {
  z-index: 5 !important;
}
.carousel-control-prev {
  z-index: 5 !important;
}

.carousel {
  position: relative;
}

.carousel {
  background: rgba(0, 0, 0, 0.942) !important;
  overflow: hidden;
}
.carousel-item img {
  object-fit: cover;
  opacity: 0.8;
}

.carousel-image {
  height: 700px !important;
  background-size: cover;
}

@media only screen and (max-width: 1400px) {
  .carousel-image {
    height: 700px !important;
    background-size: cover;
  }
}
@media only screen and (max-width: 1199px) {
  .carousel-image {
    background-size: cover;
    height: 700px !important;
  }
}
@media only screen and (max-width: 991px) {
  .carousel-image {
    background-size: cover;
    height: 465px !important;
  }
}
@media only screen and (max-width: 575px) {
  .carousel-image {
    background-size: cover;
    height: 465px !important;
  }
}

.section-1 {
  position: absolute;
  width: 100%;
  top: 40%;
}

.old-ribbon {
  position: relative;
  height: 30px;
  padding: 5px;
  top: -50px;
  font-weight: bold;
  font-size: 15px;
  margin-left: 0px;
  border-radius: 0;
  background-color: #569539;
  color: #fff;
  display: inline-block;
}

.old-ribbon::before {
  content: "";
  display: block;
  position: absolute;
  right: -15px;
  top: 0;
  border: solid 15px #569539;
  border-left-width: 0;
  border-right-color: transparent;
}

.hr-line {
  /* border:.025px solid black; */
}

.v1 {
  border-left: 0.5px solid rgba(0, 0, 0, 0.1);
  height: 25px;
  left: 50%;
}

.icon {
  width: 15px;
}

.icon-title {
  font-size: 10px;
}

.card-body-design {
  background-color: rgba(179, 178, 178, 0.354) !important;
  border-radius: 0px !important;
  margin-top: -30px !important;
}

.list-inline a {
  border: 1px solid #569539 !important;
}

.card-body-design h5 a {
  color: #569539 !important;
}

.card-body-design h5 i {
  color: #569539 !important;
}

.card-body-design .table-bordered td {
  border: 1px solid #d1d1d1;
}

.card-body-design .table tr td {
  width: 150px;
  padding: 2px !important;
}

.card-body-design .table tr td:nth-child(odd) {
  border-left: hidden !important;
}
.card-body-design .table tr td:nth-child(even) {
  border-right: hidden !important;
}

h2 {
  font-size: 2.5rem;
}
h2.double-rules {
  display: flex;
  line-height: 0.6;
  width: 100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

h2.double-rules:before {
  content: "";
  border-top: 1.5px solid;
  border-bottom: 1.5px solid;
  margin: 0 20px 0 0;
  flex: 1 0 20px;
}

h2.double-rules:after {
  margin: 0 0 0 20px;
  content: "";
  border-top: 1.5px solid;
  border-bottom: 1.5px solid;
  flex: 1 0 20px;
}

@media only screen and (max-width: 575px) {
  h2 {
    font-size: 1rem !important;
  }
  h2.double-rules {
    display: flex;
    line-height: 1;
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 2rem;
  }

  .social-media {
    width: 350px !important;
  }
}

.price-title {
  font-size: 12px;
}

h3.ribbon {
  margin: 0 auto 2rem auto;
  text-align: center;
  display: inline-block;
  position: relative;
  line-height: 1.2;
  max-width: 65vw;
}

h3.ribbon.red:after {
  background-color: #569539;
  content: "";
  display: block;
  position: absolute;
  left: -10%;
  top: -20%;
  width: 120%;
  height: 125%;
  transform: skewX(-30deg);
  box-shadow: 3px 3px 6px rgb(48 53 57 / 30%);
  z-index: -1;
}

.entry-content {
  max-width: 875px;
  margin: 0 auto;
}
.entry-content p {
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow-wrap: break-word;
}

.video-home {
  width: 100%;
  height: 100%;
}

.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

/* .video::before {
    content: '';
    display: block;
    padding-bottom: 56.25%;
}

.video {
    position: relative;
} */

/* back to top */

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10000;
  padding: 5px 10px;
  font-size: 20px;
  background: white;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #569539;
  box-shadow: 0 3px 3px rgb(15, 15, 15);
  transition: all 0.25s ease-in-out;
}

.back-to-top:hover {
  background: #569539;
  border: 1px solid #fff;
}
.back-to-top:hover i {
  color: rgb(255, 255, 255) !important;
  /* border: 1px solid rgb(130, 169, 220); */
}

.icon-style {
  background-color: #569539;
  border-radius: 50%;
  padding: 20px;
}

.icon-style2 {
  background-color: rgb(255, 0, 0);
  border-radius: 50%;
  padding: 20px;
}

.vision-short-title strong {
  color: #f8edbf;
  font-weight: 600;
}

.logo-title {
  font-size: 8px;
  /* font-family: "Passions Conflict", cursive; */
  font-family: 'Roboto Slab', serif;
  color: #d1d1d1;
  /* text-align: center; */
}

.logo-title-foot {
  font-size: 12px;
  /* font-family: "Passions Conflict", cursive; */
  font-family: 'Roboto Slab', serif;
  color: #d1d1d1;
  /* text-align: center; */
}

.logo-title-mobile {
  font-size: 18px;
  /* font-family: "Passions Conflict", cursive; */
  font-family: 'Roboto Slab', serif;
  color: #d1d1d1;
}

.logo-title-homepage {
  font-size: 32px;
  /* font-family: "Passions Conflict", cursive; */
  font-family: 'Roboto Slab', serif;
  color: #fff;
  text-shadow: 0 0 3px rgb(86, 149, 57), 0 0 5px #79c582;
}

.rectangle.tall:after {
  padding-bottom: 122.2222%;
}
.rectangle:after {
  content: "";
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
}

.team-wrap h4 {
  text-transform: uppercase;
  letter-spacing: 0.07vw;
  font-size: 12px;
}

.p-3 {
  padding: 1rem !important;
}

.team-wrap h5 {
  font-size: 1rem;
  font-style: italic;
  font-size: 12px;
}

.bg-image.cover {
  background-size: cover;
}
.rectangle {
  position: relative;
}
.bg-image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}


.team-wrap hr {
  border-top: 1.5px solid #fff;
  width: 100px;
  margin: 1rem 0;
}

/* ._li #u_0_0_M/ ._2p3a{
  width:100vw !important;
  min-width:100vw !important;
} */

.captcha-design{
  background-color: #E8EBE8 !important;
  padding: 5px;
  border-top-left-radius:5px;
  border-top-right-radius:5px;
}

.captcha-design #reload_href{
  color: green !important; 
}