/* .div-wrapper{
    height: 100vh;
    overflow: auto;
} */
.footer-Links li i{
    color: #fff;
    font-size:14px;
}

.footer-Links li a{
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
}

.footer-sub-Links li a{
    color: #fff;
    text-transform: uppercase;

}