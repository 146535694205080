@media only screen and (min-width: 1199px) {
    .col-xl-20 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 20%;
      -moz-box-flex: 0;
      -ms-flex: 0 0 20%;
      flex: 0 0 20% !important;
      max-width: 20% !important;
    }
    #homeResults {
      height: 820px;
      overflow-y: scroll;
    }
  
    .map-view {
      border: 0;
      width: 100%;
      min-height: 1000px;
    }
  }
  
  @media only screen and (min-width: 991px) {
    #homeResults {
      height: 820px;
      overflow-y: scroll;
    }
  
    .map-view {
      border: 0;
      width: 100%;
      min-height: 1000px;
    }
  }

  @media only screen and (min-width: 768px) {
    #homeResults {
      height: 820px;
      overflow-y: scroll;
    }
  
    .map-view {
      border: 0;
      width: 100%;
      min-height: 1000px;
    }
  }
  
  @media only screen and (max-width: 575px) {
    #homeResults {
      height: auto;
      overflow-y: none;
    }
  
    .map-view {
      border: 0;
      width: 100%;
      min-height: 500px;
    }
  }
  