


.price-title{
    font-size:12px;
}

.company-info p{
    font-size: 14px !important;
    font-weight: 400;
    text-align: justify;
}

.info{
    font-size: 14px !important;
}

.card-body-design-projects{
    background-color: rgba(179, 178, 178, 0.354) !important;
    border-radius:0px !important;
    margin-top:0px !important;
}

.card-body-design-projects h5 a {
  color: #569539 !important;
}
.card-body-design-projects .table-bordered td {
    border: 1px solid #d1d1d1;
}

.card-body-design-projects .table tr td{
    width:150px;
    padding: 2px !important;
}

.card-body-design-projects .table tr td:nth-child(odd){
    border-left: hidden !important;
}
.card-body-design-projects .table tr td:nth-child(even){
    border-right: hidden !important;
}

.new-ribbon{
    position:relative;
    height:30px;
    padding:5px;
    top:-50px;
    font-weight: bold;
    font-size:15px;
    margin-left:0px;
    border-radius:0;
    background-color:#ffffff;
    color:#fff;
    display:inline-block;
  }
  
  .new-ribbon::before{
    content:"";
    display:block;
    position:absolute;
    right:-15px;
    top:0;
    border:solid 15px #ffffff;
    border-left-width:0;
    border-right-color:transparent;
  }



  /* custom card */
.card-custom{position:relative; overflow:hidden; height:100%; margin-bottom:0 !important;}
.card-overlay{padding:20px; position:absolute; background:rgba(0,0,0,.5);
  left:0;  
  top: 100%;
  width:100%; height:100%;
  z-index: 1;
  transition:all 350ms cubic-bezier(0, 0, 0.2, 1);
  display:flex;
  align-items:center;
  background:rgba(79, 78, 78, 0.774) !important;
} 
.card-custom:hover .card-overlay {
  top: 0;
}

.card-custom:hover .card-custom-body {
    display: none;
}

.card-image{
    position:relative;
}
 .card-custom-body{
    position:absolute;
    font-size:15px;
    bottom:0;
    padding:10px;
    border-radius:0px !important;
    width: 100%;
    background:rgba(255, 255, 255, 0.389) !important;
}
.card-custom-body h3{
    font-size:18px;
}

.card-overlay .btn{
    padding:.35rem;
}

.nav-pills .nav-link.active{
  color:#569539 !important;
  background:none !important;
  border-bottom:3px solid #569539 !important;
  border-radius:0px !important;
}

.nav-pills .nav-item a {
  color:#569539 !important;
}


.card-overlay.left{  left:-100%;  
  top: 0;
}
.card-custom:hover .card-overlay.left {
  left: 0;
}

.card-overlay.right{  left:100%;  
  top: 0;
}
.card-custom:hover .card-overlay.right {
  left: 0;
}

.card-overlay.top{  left:0;  
  top: -100%;
}
.card-custom:hover .card-overlay.top {
  top: 0;

}
.card-overlay p{
    font-size:14px;
}

.project-details{
  background-image: url('../../../assets/LandingPage/bedRoom.jpg');
  background-repeat: no-repeat;
  background-size:cover;
  box-shadow:inset 0 0 0 2000px rgba(124, 143, 17, 0.3);
}


.card-img-top{
  height:150px !important;
  object-fit: cover !important;
}

.setHeight{
  height: 550px;
}
.card_bottom{
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 40px;
  padding-right: 15px;
}