.my-modal .modal-content {
  background-color: transparent;
  border: none;
}
.cross_position{
  margin-left: 700px;
  border: 1px solid white;
  padding: 5px 6px;
  border-radius: 50px;
  font-size: 10px;
  color: white;
  transition: .5s;
  cursor: pointer;
  font-weight: bold;
}

.cross_position:hover{
  color: black;
  border: 1px solid rgb(0, 0, 0);
  background: white;
  font-weight: bold;
}
.btn_size{
  border: none!important;
}
@media only screen and (min-width: 1200px) and (min-width: 1400px)  {
  .image_size{
    width: 600px;
    height: 600px;
  }
  .btn_size{
    width: 600px;
  }
}

@media only screen and (max-width: 990px) {
.cross_position{
  margin-left: 340px;
  margin-bottom: 5px;
}
}