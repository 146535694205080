.price-title {
  font-size: 12px;
}

.card-body-design {
  background-color: rgba(179, 178, 178, 0.354) !important;
  border-radius: 0px !important;
  margin-top: -30px !important;
}
.card-body-design .table-bordered td {
  border: 1px solid #d1d1d1;
}

.card-body-design .table tr td {
  width: 150px;
  padding: 2px !important;
}

.card-body-design .table tr td:nth-child(odd) {
  border-left: hidden !important;
}
.card-body-design .table tr td:nth-child(even) {
  border-right: hidden !important;
}

.card-body-design2 {
  background-color: rgba(255, 255, 255, 0.354) !important;
  border-radius: 0px !important;
  margin-top: 0px !important;
  border: 10px solid #569539;
}

.card-body-design2 .table-bordered td {
  border: 1px solid #d1d1d1;
}

.card-body-design2 .table tr td {
  width: 150px;
  padding: 10px !important;
}

.card-body-design2 .table tr td:nth-child(odd) {
  border-left: hidden !important;
}
.card-body-design2 .table tr td:nth-child(even) {
  border-right: hidden !important;
}

.icon-book-page {
  width: 100px !important;
}
.icon-book-page-title {
  font-size: 12px !important;
}

.new-ribbon {
  position: relative;
  height: 30px;
  padding: 5px;
  top: -50px;
  font-weight: bold;
  font-size: 15px;
  margin-left: 0px;
  border-radius: 0;
  background-color: #ffffff;
  color: #fff;
  display: inline-block;
}

.new-ribbon::before {
  content: "";
  display: block;
  position: absolute;
  right: -15px;
  top: 0;
  border: solid 15px #ffffff;
  border-left-width: 0;
  border-right-color: transparent;
}

/* custom card */
.card-custom {
  position: ralative;
  overflow: hidden;
  height: 100%;
  margin-bottom: 0 !important;
}
.card-overlay {
  padding: 20px;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 100%;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 350ms cubic-bezier(0, 0, 0.2, 1);
  display: flex;
  align-items: center;
  background: rgba(79, 78, 78, 0.774) mportant;
}
.card-custom:hover .card-overlay {
  top: 0;
}

.card-custom:hover .card-custom-body {
  display: none;
}

.card-image {
  position: relative;
}
.card-custom-body {
  position: absolute;
  font-size: 15px;
  bottom: 0;
  padding: 10px;
  border-radius: 0px !important;
  width: 100%;
  background: rgba(255, 255, 255, 0.389) !important;
}
.card-custom-body h3 {
  font-size: 18px;
}

.card-overlay .btn {
  padding: 0.35rem;
}

/* .box:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height:20%;
    transform: scaleY(0);
    transform-origin: bottom center;
    background: #ffffff;
    opacity: 60%;
    z-index: 1;
    transition: transform 0.3s;
  }
  .box:hover::after {
    transform: scaleY(1);
  }
  
  .box:hover .title {
    z-index: 2;
    opacity: 1;
    left: 0%;
    width: 100%;
    top: 95%;
    margin-bottom: 110px;
  } */
.card-overlay.left {
  left: -100%;
  top: 0;
}
.card-custom:hover .card-overlay.left {
  left: 0;
}

.card-overlay.right {
  left: 100%;
  top: 0;
}
.card-custom:hover .card-overlay.right {
  left: 0;
}

.card-overlay.top {
  left: 0;
  top: -100%;
}
.card-custom:hover .card-overlay.top {
  top: 0;
}
.card-overlay p {
  font-size: 14px;
}

.custom-toggle-button .nav-link.active .btn {
  color: #FBFBFC !important;
  background:  #569539 !important;
  
}

.custom-toggle-button .nav-link .btn {
  color: #569539 !important;
  background:  #FBFBFC !important;
  
}


.custom-toggle-button .nav-link{
  padding:0.5rem 0rem;
}

.custom-toggle-button .btn{
  border-radius: 0px !important;
}


.btn-design{
  background-color: #569539 !important;
  border: 1px solid #569539 !important;
  color: #fff !important;
}

.btn-design:hover{
  background-color: #000 !important;
  border: 1px solid #569539 !important;
  color: #fff !important;
}